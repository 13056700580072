<template>
  <section></section>
</template>

<script>
import * as dd from 'dingtalk-jsapi';
import setting from '@/setting';

export default {
  name: 'Login',
  async mounted() {
    if (process.env.NODE_ENV == 'localhost') {
      const { token, user } = await this.$http.post('/login_test', {
        code: setting.userId
      });
      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(user));
      await this.$router.replace('/');
    } else {
      await this.login();
    }
  },
  methods: {
    login() {
      dd.ready(async () => {
        const { code } = await dd.runtime.permission.requestAuthCode({
          corpId: setting.corpId
        });
        const { token, user } = await this.$http.post('/login', { code });
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(user));
        await this.$router.replace('/');
      });
    }
  }
};
</script>

<style lang="scss"></style>
